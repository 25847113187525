<div class="Role"></div>
<!--    角色管理-->
<template>
    <div class="Role" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="100px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="角色名称/编码">
                            <el-input placeholder="" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <!--                    <el-col :span="8">-->
                    <!--                        <el-form-item label="类型">-->
                    <!--                            <el-select placeholder="类型" v-model="form.deptType">-->
                    <!--                                <el-option label="请选择" value="" />-->
                    <!--                                <el-option label="总部" value="1" />-->
                    <!--                                <el-option label="配送中心" value="2" />-->
                    <!--                                <el-option label="门店" value="3" />-->
                    <!--                            </el-select>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select filterable v-model="form.source" :loading="loadingDeptGroupFlag">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery(1)"
                size="small"
                v-if="hasPrivilege('menu.system.deptGroup.role.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.system.deptGroup.role.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.system.deptGroup.role.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="编码" width="180" prop="code" v-if="showColumn('code')" />
                <el-table-column label="角色名称" width="160" prop="name" v-if="showColumn('name')" />
                <el-table-column label="角色类型" width="100" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="使用范围"
                    width="100"
                    prop="openType"
                    v-if="showColumn('openType')"
                    key="openType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.openType | openType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="权重" width="80" prop="weight" v-if="showColumn('weight')" />
                <el-table-column label="机构组" width="80" prop="deptGroup" v-if="showColumn('deptGroup')" />

                <!--                <el-table-column-->
                <!--                    label="归属类型"-->
                <!--                    width="100"-->
                <!--                    prop="deptType"-->
                <!--                    v-if="showColumn('deptType')"-->
                <!--                    key="deptType"-->
                <!--                >-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <span>{{ scope.row.deptType | deptType }}</span>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column label="备注" min-width="300" prop="remark" v-if="showColumn('remark')" />

                <el-table-column
                    label="操作"
                    width="280"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope" v-if="scope.row.code != 'TDSROL00000000000001'">
                        <el-button
                            size="small"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.system.deptGroup.role.edit')"
                            >编辑
                        </el-button>
                        <el-button
                            size="small"
                            type="success"
                            @click="handleMenu(scope.row)"
                            v-if="hasPrivilege('biz.deptGroupRoleMenu.query')"
                            >菜单
                        </el-button>
                        <el-button
                            size="small"
                            type="primary"
                            @click="handlePrivilege(scope.row)"
                            v-if="hasPrivilege('biz.deptGroupRolePrivilege.query')"
                            >权限
                        </el-button>
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.system.deptGroup.role.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    components: { CheckTableShowColumnDialog },

    mixins: [CheckTableShowColumn],

    name: 'Role',
    data() {
        return {
            form: {
                deptType: '',
                search: '',
                source: '',
                page: 1,
                limit: Util.Limit,
            },
            loadingDeptGroupFlag: true,
            meta: {
                groups: [],
                deptCodes: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/system/deptGroupRole/page',
                delete: '/system/deptGroupRole/delete',
            },
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        deptType(deptType) {
            if (typeof deptType == 'undefined') {
                return '';
            }
            if (deptType == '1') {
                return '总部电商';
            }
            if (deptType == '2') {
                return '配送中心';
            }
            if (deptType == '3') {
                return '门店';
            }
        },
        openType(openType) {
            if (typeof openType == 'undefined') {
                return '';
            }
            if (openType == '1') {
                return '私有';
            }
            if (openType == '0') {
                return '公开';
            }
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '1') {
                return '业务型';
            }
            if (type == '0') {
                return '管理型';
            }
        },
    },
    methods: {
        handleQuery(page) {
            const _this = this;
            if (page) {
                _this.form.page = page;
            }
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.system.deptGroup.role.create', ['系统管理', '角色管理', '创建角色']);
        },
        handleExport() {
            UrlUtils.Export(this, '角色', '/system/deptGroupRole/export', this.form);
        },
        handleEdit(row) {
            Util.nameJump(this, 'menu.system.deptGroup.role.edit', ['系统管理', '角色管理', '编辑角色'], {
                form: row,
                code: row.code,
            });
        },
        handleMenu(row) {
            Util.nameJump(this, 'menu.system.deptGroup.role.menu', ['系统管理', '角色管理', '编辑角色菜单'], {
                form: row,
                code: row.code,
            });
        },
        handlePrivilege(row) {
            Util.nameJump(this, 'menu.system.deptGroup.role.privilege', ['系统管理', '角色管理', '编辑角色权限'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
    },
};
</script>

<style scoped>
.Role .el-form-item {
    margin-bottom: 0;
}
</style>
